<template>
    <div>
        <div v-show="isLoading" class="Loader">
            <i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>
        </div>
        <!-- ============================================================== -->
        <!-- Main wrapper - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <div id="main-wrapper">
            <Header></Header>
            <div v-if="hasValidationError" class="error-area">
                <p v-for="item in allValidationError" :key="item.validationText">
                    ・{{item.validationText}}
                </p>
            </div>
            <section class="fixed">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>企業紐づけ</label>
                                <button type="submit" class="btn-md full-width pop-login" @click="approve">承認</button>
                                <button type="submit" class="btn-md full-width pop-login" @click="reject">否認</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import MixIn from "@/common/mixin";
    import ApiMixIn from "@/common/api_mixin";

    export default {
        name: "LinkCompany",
        components: {Footer, Header},
        mixins: [MixIn, ApiMixIn],
        created: function () {
            const token = this.$route.query.token;
            if (!token) {
                this.pushError("URLが正しくありません。");
            }
            this.verify(token);
        },
        methods: {
            async verify(token)
            {
                await this.postApi("company/verifyLinkCompanyToken", { token: token });
            },
            async approve() {
                const response = await this.postApi("company/approveLinkCompany", { token: this.$route.query.token }, true);
                if (response) {
                    this.pushResult("紐づけ完了しました", "自動で紐づけ完了メールが送信されます")
                }
            },
            async reject() {
                const response = await this.postApi("company/rejectLinkCompany", { token: this.$route.query.token }, true);
                if (response) {
                    this.pushResult("紐づけ拒否しました", "自動で紐づけ拒否メールが送信されます")
                }
            }
        }
    }
</script>

<style scoped>

</style>
