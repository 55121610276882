<template>
    <div>
      <transition name="vload">
        <div v-show="isLoading" class="Loader">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
          <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
        </div>
      </transition>
        <!-- ============================================================== -->
        <!-- Main wrapper - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <div id="main-wrapper">
            <Header></Header>
          <section class="fixed mypage-contents-fixed">
            <div class="container">
              <div class="row">
                <MyPageContents class="col-lg-3 col-sm-12" v-on:logout="logout" v-on:delete-account="deleteAccount" :user-data="userData"
                                :validation-errors="deleteAccountValidationErrors"></MyPageContents>
                <div class="col-lg-9 col-sm-12 mx-auto mypage-right-frame">
                  <div class="form-title-group">
                    <div class="menu-active-mark" style="margin-top: 10px;"/>
                    <div class="form-title"><h1>ユーザー招待</h1></div>
                  </div>
                  <div class="menu-bar"/>
                  <p class="form-text">企業情報や求人を管理するユーザーを招待することができます。招待ユーザーがアカウント未作成の場合、アカウント作成を依頼してください。</p>
                  <div v-if="hasValidationError" class="error-area">
                    <p v-for="item in allValidationError" :key="item.validationText">
                      ・{{item.validationText}}
                    </p>
                  </div>
                  <p v-if="isInvited" class="result-text-success">・招待メールの送信が完了しました。</p>
                  <form @submit.prevent="invitation">
                    <div class="form-group" style="display: flex; margin-bottom: 15px">
                      <label style="width: 190px; padding-top: 12px">招待メールアドレス</label>
                      <label style="width: 100% !important;">
                        <input type="email" class="form-control" style="margin-left: 0;" placeholder="example@test.com"
                               v-model="mailAddress">
                      </label>
                    </div>
                    <div class="form-group" style="border: none !important;">
                      <button type="submit" class="btn-md full-width pop-login" style="margin:0;" :disabled="!mailAddress">
                        送信
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <Footer></Footer>
        </div>
    </div>
</template>

<script>
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import MyPageContents from "@/views/components/MyPageContents";
    import MyPageMixIn from "@/common/mypage_mixin";

    export default {
        name: "UserInvitation",
        components: {Footer, Header, MyPageContents},
        mixins: [MyPageMixIn],
        data: function () {
            return {
                mailAddress: "",
                isInvited: false
            }
        },
        methods: {
            async invitation() {
                const response = await this.postApi("user/groupInvitation", {
                    mailAddress: this.mailAddress
                });
                if (response) {
                    this.isInvited = true;
                    this.mailAddress = "";
                } else {
                    this.isInvited = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>
