<template>
    <div>
      <transition name="vload">
        <div v-show="isLoading" class="Loader">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
          <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
        </div>
      </transition>
        <!-- ============================================================== -->
        <!-- Main wrapper - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <div id="main-wrapper">
            <Header></Header>
          <section class="fixed mypage-contents-fixed">
            <div class="container">
              <div class="row">
                <MyPageContents class="col-lg-3 col-sm-12" v-on:logout="logout" v-on:delete-account="deleteAccount" :user-data="userData" :validation-errors="deleteAccountValidationErrors"></MyPageContents>
                <div v-if="inviteUser" class="col-lg-9 col-sm-12 mx-auto mypage-right-frame">
                  <div class="form-title-group">
                    <div v-if="inviteUser" class="menu-active-mark" style="margin-top: 10px;"/>
                    <div class="form-title"><h1>企業グループへの招待</h1></div>
                  </div>
                  <div class="menu-bar"/>
                  <p class="form-text">{{inviteUser.name}}さんから{{inviteCompanyData.name}}の企業グループへの
                    招待が届いております。内容に問題ない場合は「参加する」ボタンを押してください。</p>
                  <div v-if="hasValidationError" class="error-area">
                    <p v-for="item in allValidationError" :key="item.validationText">
                      ・{{item.validationText}}
                    </p>
                  </div>
                  <!-- Single Item -->
                  <div class="_large_jb_list72 shadow_0">
                    <div class="_large_jb_header mypage-company-list">
                      <div class="_list_110">
                        <div class="_list_110_caption">
                          <div class="company-name"><h4 class="_jb_title">{{inviteCompanyData.name}}</h4></div>
                          <div class="_emp_jb">所在地：{{inviteCompanyData.address}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <button type="submit" class="btn-md full-width pop-login form-button" @click="join">参加する</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import MixIn from "@/common/mixin";
    import ApiMixIn from "@/common/api_mixin";
    import MyPageContents from "@/views/components/MyPageContents.vue";
    import CompanyForm from "@/views/company/components/CompanyForm.vue";

    export default {
        name: "JoinGroup",
        components: {MyPageContents, Footer, Header},
        mixins: [MixIn, ApiMixIn],
        data: function() {
            return {
                inviteUser: null
            }
        },
        computed: {
            inviteCompanyData: {
                get() {
                    if (!this.inviteUser || !this.inviteUser.userGroup) {
                        return null;
                    }
                    return this.inviteUser.userGroup.company;
                }
            }
        },
        created: function () {
            const token = this.$route.query.token;
            if (!token) {
                this.pushError("URLが正しくありません。");
                return;
            }
            this.verifyToken(token);
        },
        methods: {
            async verifyToken(token) {
                const response = await this.postApi("user/verifyJoinGroupToken", { token: token });
                if (response) {
                    this.inviteUser = response.data.inviteUser;
                }
            },
            async join() {
                const response = await this.postApi("user/joinGroup", {
                    token: this.$route.query.token
                }, true);
                if (response) {
                    this.pushResult("参加完了", "企業グループに参加しました")
                }
            }
        }
    }
</script>

<style scoped>

</style>
